import { Text, Box, Stack, chakra } from '@chakra-ui/react';
import React from 'react';

interface Props {
  error: Error;
}

export const ErrorOverlay = (props: Props) => {
  return (
    <Box maxW="1200px" mx="auto">
      <Box mt={8}>
        <Stack p={8} spacing={8} rounded="xl" bg="red.100" shadow="popover">
          <Text fontSize="xl" fontWeight="bold">
            Something went wrong:
          </Text>
          <chakra.pre fontWeight="bold">{props.error.message}</chakra.pre>
          <chakra.pre>{props.error.stack}</chakra.pre>
        </Stack>
      </Box>
    </Box>
  );
};
