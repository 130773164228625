import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DividerWithContent } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaCopy, FaShare } from 'react-icons/fa';
import * as Yup from 'yup';
import { FormSection } from '../../components/forms/FormSection';
import { HookedAutoCompleteEmail } from '../../components/forms/hookform/HookedAutoCompleteEmail';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { HookedTextArea } from '../../components/forms/hookform/HookedTextArea';
import { BulletSteps } from '../../components/generic/BulletSteps';
import { useShareReportImportMutation } from '../../graphql/hooks/useShareReportImportMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  onClose: () => void;
  onBack: () => void;
}

interface FormValues {
  email: string;
  message?: string;
}

export const ExternalReportUploadUrl = (props: Props) => {
  const { onClose, onBack } = props;

  const { organization, config, successToast, errorToast } = useAppContext();
  const mutation = useShareReportImportMutation();

  const initialValues = { email: '', message: undefined };
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const url = React.useMemo(
    () => `${config.auth.redirectUri}/upload/${organization.shortId}`,
    [config.auth.redirectUri, organization.shortId],
  );

  const onShareEmail = React.useCallback(
    (values: FormValues) => {
      mutation
        .mutateAsync({
          organizationId: organization.id,
          shareReportImportRequest: {
            toEmail: values.email,
            message: values.message,
            uploadUrl: url,
          },
        })
        .then(() => {
          successToast(`Email shared successfully`);
          onClose();
        })
        .catch((err) => errorToast(`Error sending email: ${err.message}`));
    },
    [mutation, organization.id, url, successToast, onClose, errorToast],
  );

  const onCopyToClipboard = React.useCallback(() => {
    successToast('URL copied to clipboard');
    navigator.clipboard.writeText(url);
  }, [successToast, url]);

  return (
    <>
      <ModalHeader>
        <Flex align="start">
          <Box>
            <Heading size="md">External Upload Referral</Heading>
            <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
              Either copy the url directly, or send via email to your contact
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <BulletSteps currentStep={2} totalSteps={2} />
            <ModalCloseButton position="unset" top="unset" right="unset" />
          </HStack>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <Stack
            as="form"
            onSubmit={methods.handleSubmit(onShareEmail)}
            align="start"
            spacing={8}
            w="full"
          >
            <FormSection
              heading="Share URL"
              description="Anyone who has this link will be able to upload studies into your account."
            >
              <HStack spacing={2} w="full">
                <Input value={url} size="sm" isReadOnly w="sm" />
                <Button
                  size="sm"
                  colorScheme="purple"
                  leftIcon={<FaCopy />}
                  onClick={onCopyToClipboard}
                >
                  Copy
                </Button>
              </HStack>
            </FormSection>
            <DividerWithContent w="full">
              <Text fontSize="sm" fontWeight="semibold" color="gray.400">
                Or
              </Text>
            </DividerWithContent>
            <FormSection
              heading="Share by email"
              description="Enter the email address of the person you would like to share this link with."
            >
              <HookedFormItem
                label="Email"
                name="email"
                data-pw="externalReportUploadUrlEmailFormItem"
              >
                <HookedAutoCompleteEmail
                  name="email"
                  data-pw="externalReportUploadUrlAutocomplete"
                  isDisabled={false}
                  placeholder="Enter an email"
                />
              </HookedFormItem>
              <HookedFormItem
                label="Message"
                name="message"
                data-pw="externalReportUploadUrlMessageFormItem"
              >
                <HookedTextArea
                  name="message"
                  placeholder="Enter a message"
                  data-pw="externalReportUploadUrlMessageTextarea"
                  size="sm"
                  minRows={4}
                  maxRows={8}
                />
              </HookedFormItem>
              <HookedSubmitButton
                leftIcon={<FaShare />}
                isLoading={mutation.isLoading}
                loadingText="Sharing..."
                data-pw="externalReportUploadUrlSubmitButton"
              >
                Share
              </HookedSubmitButton>
            </FormSection>
          </Stack>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button onClick={onBack}>Back</Button>
          <Button onClick={onClose}>Close</Button>
        </HStack>
      </ModalFooter>
    </>
  );
};
