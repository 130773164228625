import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useConfig } from '../../context/ConfigContext';
import { useCountdown } from '../../hooks/useCountdown';
import { Result } from '@piccolohealth/ui';

export const IdleLogoutModal = () => {
  const { config } = useConfig();
  const { logout, isAuthenticated } = useAuth0();
  const [isIdle, setIsIdle] = React.useState(false);
  const { start, seconds, stop } = useCountdown(config.settings.idleWarningTimeout / 1000);

  const handleOnIdle = React.useCallback(() => {
    setIsIdle(true);
    start();
  }, [setIsIdle, start]);

  const handleLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  const idleTimer = useIdleTimer({
    startManually: true,
    startOnMount: false,
    timeout: config.settings.idleTimeout,
    stopOnIdle: true,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  const handleIdleReset = React.useCallback(() => {
    setIsIdle(false);
    idleTimer.reset();
    stop();
  }, [setIsIdle, idleTimer, stop]);

  React.useEffect(() => {
    if (isAuthenticated) {
      idleTimer.start();
    }
  }, [isAuthenticated, idleTimer]);

  React.useEffect(() => {
    if (seconds <= 0) {
      handleLogout();
    }
  }, [seconds, handleLogout]);

  return (
    <Modal isOpen={isIdle} onClose={handleIdleReset} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Result
            status="warning"
            title="Session expiry warning"
            description="Your session is about to expire due to inactivity. You will be logged out in:"
            extra={<Heading size="xl">{seconds} seconds</Heading>}
            p={4}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={handleIdleReset} colorScheme="purple">
              Continue session
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
