import { ReportTemplateChoiceVariable } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { useUpdateReportTemplateVariableMutation } from '../../../../graphql/hooks/useUpdateReportTemplateVariableMutation';
import { useAppContext } from '../../../../hooks/useAppContext';
import { FormValues, VariableForm } from './VariableForm';

interface Props {
  reportTemplateVariable: ReportTemplateChoiceVariable;
  onExit: () => void;
}

export const EditVariablePage = (props: Props) => {
  const { reportTemplateVariable, onExit } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const { reportTemplate } = useReport();

  const mutation = useUpdateReportTemplateVariableMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          reportTemplateId: reportTemplate.id,
          reportTemplateVariableId: reportTemplateVariable.id,
          request: {
            __typename: 'ReportTemplateChoiceVariable',
            id: values.id,
            label: values.name,
            site: values.site,
            category: values.category,
            controlType: 'SELECT',
            defaultValue: _.find(values.choices, (c) => c.default)?.value,
            choices: _.map(values.choices, (v) => ({
              ...v,
              label: v.value,
            })),
            mappings: reportTemplateVariable.mappings.map((mapping) => mapping.id),
            isEditable: true,
          },
        })
        .then(() => {
          successToast('Variable edited successfully');
          onExit();
        })
        .catch((err) => {
          errorToast(`Error editing variable: ${err.message}`);
        });
    },
    [mutation, organization.id, reportTemplate.id, reportTemplateVariable.id, reportTemplateVariable.mappings, successToast, onExit, errorToast],
  );

  const initialValues = {
    id: reportTemplateVariable.id,
    name: reportTemplateVariable.label,
    site: reportTemplateVariable.site,
    category: reportTemplateVariable.category,
    choices: reportTemplateVariable.choices,
  };

  return <VariableForm initialValues={initialValues} onSubmit={onSubmit} />;
};
