import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React from 'react';
import { AuthorizationDebug } from './AuthorizationDebug';
import { ConfigDebug } from './ConfigDebug';
import { Content } from '../../../components/generic/Content';

export const Debug = () => {
  return (
    <Content>
      <Tabs colorScheme="purple" w="100%" h="100%">
        <TabList>
          <Tab key="config">Config</Tab>
          <Tab key="authorization">Authorization</Tab>
        </TabList>
        <TabPanels h="100%">
          <TabPanel>
            <ConfigDebug />
          </TabPanel>
          <TabPanel>
            <AuthorizationDebug />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Content>
  );
};
