import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from '@piccolohealth/ui';

export const OrganizationNotFound = () => {
  return (
    <Result
      status="error"
      title="404"
      description="Sorry, no organization could be found. Please contact support to sign up."
      extra={
        <Link to={'/logout'}>
          <Button colorScheme="purple">Logout</Button>
        </Link>
      }
    />
  );
};
