import { Box, Flex, Stack } from '@chakra-ui/react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import React from 'react';
import { useQuery } from 'react-query';
import { RadioGroup } from '@piccolohealth/ui';
import { Card, CardHeader } from '../../components/generic/Card';
import { Content } from '../../components/generic/Content';
import { Divider } from '../../components/generic/Divider';
import { Spin } from '@piccolohealth/ui';
import { CubeBarTimeChart } from '../../components/insights/cubejs/CubeBarTimeChart';
import { CubeCard } from '../../components/insights/cubejs/CubeCard';
import { CubePieChart } from '../../components/insights/cubejs/CubePieChart';
import { CubeTable } from '../../components/insights/cubejs/CubeTable';
import { CubeValue } from '../../components/insights/cubejs/CubeValue';
import { queries } from '../../components/insights/cubejs/queries';
import { useAppContext } from '../../hooks/useAppContext';
import { TimeRange, useTimeRangeCubeQuery } from '../../hooks/useTimeRangeCubeQuery';

export const Insights = () => {
  const { config, auth, organization } = useAppContext();

  const { timeRangeOptions, timeRange, setTimeRange, withTimeRange, withHistoricalTimeRange } =
    useTimeRangeCubeQuery();

  const { isLoading, data: token } = useQuery<string>(
    ['access-token'],
    auth.getAccessTokenSilently,
  );

  if (isLoading || !token) {
    return <Spin />;
  }

  const cubejsApi = cubejs('piccolo', {
    apiUrl: config.api.cubeJs,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-OrganizationId': organization.id,
    },
  });

  return (
    <Content title="Insights">
      <CubeProvider cubejsApi={cubejsApi}>
        <Flex h="100%" direction="column">
          <Box px={4}>
            <RadioGroup
              options={timeRangeOptions}
              value={timeRange}
              onChange={(e) => setTimeRange(e as TimeRange)}
            />
          </Box>
          <Flex w="100%" direction="row" flexGrow={1} gridGap={8} p={4}>
            <Flex direction="column" w="100%" h="100%" flexGrow={1} gridGap={8}>
              <Card flexGrow={0} flexShrink={0} h="150px" alignItems="center">
                <Stack direction="row" h="100%" w="100%" spacing={30} justifyContent="space-evenly">
                  <Flex h="100%" direction="column">
                    <CardHeader title="Reports uploaded" />
                    <CubeValue
                      query={withHistoricalTimeRange(queries.reportsUploadedCount)}
                      timeRange={timeRange}
                    />
                  </Flex>
                  <Divider orientation="vertical" />
                  <Flex h="100%" direction="column">
                    <CardHeader title="Reports finalized" />
                    <CubeValue
                      query={withHistoricalTimeRange(queries.reportsFinalizedCount)}
                      timeRange={timeRange}
                    />
                  </Flex>
                  <Divider orientation="vertical" />
                  <Flex h="100%" direction="column">
                    <CardHeader title="Reports shared" />
                    <CubeValue
                      query={withHistoricalTimeRange(queries.reportsSharedCount)}
                      timeRange={timeRange}
                    />
                  </Flex>
                  <Divider orientation="vertical" />
                  <Flex h="100%" direction="column">
                    <CardHeader title="Unique patients" />
                    <CubeValue
                      query={withHistoricalTimeRange(queries.reportsByUniquePatientName)}
                      timeRange={timeRange}
                    />
                  </Flex>
                </Stack>
              </Card>

              <Box h="100%">
                <CubeCard title="Reports uploaded">
                  <CubeBarTimeChart query={withTimeRange(queries.reportsUploadedOverTime)} />
                </CubeCard>
              </Box>
            </Flex>
            <Box h="100%">
              <Card w="xl" h="100%">
                <Flex direction="column" h="100%">
                  <Box flexBasis={0} flexGrow={1}>
                    <CardHeader title="Sonographers" />
                    <CubeTable query={withTimeRange(queries.reportsBySonographer)} limit={4} />
                  </Box>

                  <Box flexBasis={0} flexGrow={1}>
                    <CardHeader title="Cardiologists" />
                    <CubeTable query={withTimeRange(queries.reportsByCardiologist)} limit={4} />
                  </Box>

                  <Box flexBasis={0} flexGrow={1}>
                    <CardHeader title="Referring Doctors" />
                    <CubeTable query={withTimeRange(queries.reportsByReferrer)} limit={4} />
                  </Box>
                </Flex>
                <Divider />
                <Box h="3xs" flexGrow={0} flexShrink={0} p={0} mb={4}>
                  <CardHeader title="Report types" />
                  <CubePieChart query={withTimeRange(queries.reportsByReportTemplateName)} />
                </Box>
              </Card>
            </Box>
          </Flex>
        </Flex>
      </CubeProvider>
    </Content>
  );
};
