import gql from 'graphql-tag';

export const ShareReportImport = gql`
  mutation ShareReportImport(
    $organizationId: ID!
    $shareReportImportRequest: ShareReportImportRequest!
  ) {
    shareReportImport(organizationId: $organizationId, request: $shareReportImportRequest)
  }
`;
