import { P } from '@piccolohealth/util';
import {
  Instance,
  interleave,
  isPlayableDicom,
  Stage,
  Study,
  View,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useFullscreen, useLocalStorage, useMethods } from 'react-use';
import { useHotkey } from '../../../hooks/useHotkey';
import { ScrollDirection, useScroll } from '../../../hooks/useScroll';
import { insideBound } from '../../../utils/overlap';
import { Layout, layoutToCount } from '../controls/Layout';
import { ImageViewerMode, ImageViewerModeType } from '../controls/ModeControl';
import { Sync } from '../controls/SyncControl';

const DEFAULT_MODE = {
  type: ImageViewerModeType.Normal,
} as ImageViewerMode;
const DEFAULT_IS_PLAYING = true;
const DEFAULT_IS_FULLSCREEN = false;
const DEFAULT_LAYOUT = Layout.layout2x2;
const DEFAULT_SYNC = Sync.None;
const DEFAULT_CURRENT_INDEX = 0;
const DEFAULT_BRIGHTNESS = 1.0;
const DEFAULT_CONTRAST = 1.0;
const DEFAULT_SPEED = 1.0;
const DEFAULT_TOOLBAR_POSITION = 'top' as const;

interface SynchronizationChild {
  id: string;
  duration: number;
  numberOfFrames: number;
  currentTime: number;
  frameRate: number;
  setFrameRate: (targetFrameRate: number) => void;
  setTime: (targetTime: number) => void;
  getTime: () => number;
}

export class Synchronizer {
  enabled: boolean;
  children: SynchronizationChild[];

  constructor() {
    this.enabled = false;
    this.children = [];
  }

  register(options: SynchronizationChild) {
    this.children.push(options);
  }

  deregister(id: string) {
    this.children = this.children.filter((c) => c.id !== id);
  }

  reset() {
    this.children = [];
  }

  setEnabled(value: boolean) {
    this.enabled = value;
  }

  onPlaybackProgress(id: string, currentTime: number, currentSpeed: number): void {
    if (!this.enabled) {
      return;
    }

    const child = this.getChild(id);
    if (!child) {
      return;
    }
    child.currentTime = currentTime;

    const master = this.getMaster();
    if (!master) {
      return;
    }

    const isMaster = master.id === id;
    if (!isMaster) {
      const childProgress = child.currentTime / child.duration;
      const masterProgress = master.currentTime / master.duration;

      const diff = Math.abs(masterProgress - childProgress);
      const progressDiff = Math.min(1.0 - diff, diff);

      const targetFrameRate = (child.duration / master.duration) * child.frameRate * currentSpeed;
      child.setFrameRate(targetFrameRate);

      if (progressDiff > 0.1) {
        const targetTime = masterProgress * child.duration;
        child.setTime(targetTime);
      }
    }
  }

  getChild(id: string) {
    return this.children.find((c) => c.id === id);
  }

  getMaster() {
    return P.maxBy(this.children, (c) => c.duration);
  }

  scroll(direction: ScrollDirection) {
    const master = this.getMaster();
    if (!master) {
      return;
    }

    this.children.forEach((c) => {
      const singleTick = c.duration / master.numberOfFrames;
      const singleTickWithDirection = direction === ScrollDirection.Down ? singleTick : -singleTick;
      const currentTime = c.getTime();

      const firstFrameTime = 0;
      const lastFrameTime = c.duration - singleTick;
      const targetCurrentTime = P.clamp(
        currentTime + singleTickWithDirection,
        firstFrameTime,
        lastFrameTime,
      );
      c.setTime(targetCurrentTime);
    });
  }
}

export interface InstanceStack {
  instances: Instance[];
  stage: Stage;
  view: View;
}

interface ImageViewerState {
  mode: ImageViewerMode;
  isPlaying: boolean;
  isFullscreen: boolean;
  layout: Layout;
  sync: Sync;
  brightness: number;
  contrast: number;
  speed: number;
  toolbarPosition: 'top' | 'bottom';
  currentIndex: number;
  isReportShowing: boolean;
  hasStressInstances: boolean;
  instances: Instance[];
  instancesStacks: InstanceStack[];
  playerInstances: Instance[];
  synchronizer: Synchronizer;
}

function imageViewerMethods(state: ImageViewerState) {
  return {
    pause() {
      return { ...state, isPlaying: false };
    },
    play() {
      return { ...state, isPlaying: true };
    },
    playPause() {
      return { ...state, isPlaying: !state.isPlaying };
    },
    changeBrightness(brightness: number) {
      return {
        ...state,
        brightness,
      };
    },
    changeContrast(contrast: number) {
      return {
        ...state,
        contrast,
      };
    },
    changeSpeed(speed: number) {
      const playerInstances = getInitialPlayerInstances(state.instancesStacks, state.mode);

      return {
        ...state,
        speed,
        playerInstances,
      };
    },
    changeMode(mode: ImageViewerMode) {
      const instancesStacks = getInitialInstancesStack(state.instances, mode);
      const playerInstances = getInitialPlayerInstances(instancesStacks, mode);
      const layout = mode.type === ImageViewerModeType.Stress ? Layout.layout1x2 : Layout.layout2x2;
      const sync = mode.type === ImageViewerModeType.Stress ? Sync.Rate : Sync.None;
      state.synchronizer.setEnabled(sync === Sync.Rate);

      return {
        ...state,
        mode,
        layout,
        sync,
        instancesStacks,
        playerInstances,
        currentIndex: DEFAULT_CURRENT_INDEX,
      };
    },
    changeLayout(layout: Layout) {
      return {
        ...state,
        layout,
      };
    },
    changeToolbarPosition(toolbarPosition: 'top' | 'bottom') {
      return {
        ...state,
        toolbarPosition,
      };
    },
    changeFullscreen(value: boolean) {
      return {
        ...state,
        isFullscreen: value,
      };
    },
    seek(count: number) {
      if (state.mode.type === ImageViewerModeType.Stress) {
        const proposedNextCurrentIndex = state.currentIndex + count;
        const newStackNeeded =
          proposedNextCurrentIndex >= state.playerInstances.length || proposedNextCurrentIndex < 0;

        if (newStackNeeded) {
          const views = P.uniq(state.instancesStacks.map(({ view }) => view));
          const currentView = state.mode.view;
          const nextView =
            views.at(
              (views.findIndex((view) => view === currentView) + Math.sign(count) + views.length) %
                views.length,
            ) ?? currentView;
          const nextMode = {
            type: ImageViewerModeType.Stress,
            view: nextView,
          };

          const playerInstances = getInitialPlayerInstances(
            state.instancesStacks,
            nextMode ?? state.mode,
          );
          return { ...state, currentIndex: DEFAULT_CURRENT_INDEX, playerInstances, mode: nextMode };
        } else {
          return { ...state, currentIndex: proposedNextCurrentIndex };
        }
      } else {
        const proposedIndex = state.currentIndex + count;

        let nextCurrentIndex;
        if (proposedIndex < 0) {
          nextCurrentIndex = proposedIndex + state.playerInstances.length;
        } else if (proposedIndex >= state.playerInstances.length) {
          nextCurrentIndex = 0;
        } else {
          nextCurrentIndex = proposedIndex;
        }

        return { ...state, currentIndex: nextCurrentIndex };
      }
    },
    scroll(direction: ScrollDirection) {
      state.synchronizer.scroll(direction);
      return this.pause();
    },
    nextClip() {
      const count = layoutToCount(state.layout);
      return this.seek(count);
    },
    previousClip() {
      return this.seek(-layoutToCount(state.layout));
    },
    nextFrame() {
      return this.scroll(ScrollDirection.Down);
    },
    previousFrame() {
      return this.scroll(ScrollDirection.Up);
    },
    clickInstanceStack(instanceStack: InstanceStack) {
      const mode = {
        ...state.mode,
        view: instanceStack.view,
      };
      const currentIndex =
        mode.type === ImageViewerModeType.Normal
          ? state.instancesStacks.findIndex((stack) => stack === instanceStack)
          : 0;
      const playerInstances = getInitialPlayerInstances(state.instancesStacks, mode);
      return { ...state, currentIndex, mode, playerInstances };
    },
    toggleShowReport() {
      return {
        ...state,
        isReportShowing: !state.isReportShowing,
      };
    },
  };
}

const getInitialInstancesStack = (
  instances: Instance[],
  mode: ImageViewerMode,
): InstanceStack[] => {
  if (mode.type === ImageViewerModeType.Stress) {
    const grouped = _.chain(instances)
      .reject(
        (instance) =>
          instance.dicom.stage === Stage.Unknown ||
          instance.dicom.view === View.Unknown ||
          !instance.mp4Url,
      )
      .groupBy((instance) => `${instance.dicom.stage}-${instance.dicom.view}`)
      .map((instances) => {
        const stage = instances[0]?.dicom.stage || Stage.Unknown;
        const view = instances[0]?.dicom.view || View.Unknown;
        // Take the latest instance for a view.
        const sortedByLatest = P.orderBy(
          instances,
          (instance) => instance.dicom.instanceNumber,
        ).reverse();

        return {
          instances: sortedByLatest,
          stage,
          view,
        };
      })
      .sortBy(['view', 'stage'])
      .reverse()
      .value();

    return grouped;
  }

  return instances.map((instance) => {
    return {
      instances: [instance],
      stage: instance.dicom.stage ?? Stage.Unknown,
      view: instance.dicom.view ?? View.Unknown,
    };
  });
};

const getInitialPlayerInstances = (
  instancesStacks: InstanceStack[],
  mode: ImageViewerMode,
): Instance[] => {
  if (mode.type === ImageViewerModeType.Stress) {
    const viewStack = instancesStacks.filter((stack) => stack.view === mode.view);
    const restStack = viewStack.find((stack) => stack.stage === Stage.Rest)?.instances || [];
    const postStack = viewStack.find((stack) => stack.stage === Stage.Post)?.instances || [];
    const length = Math.min(restStack.length, postStack.length);
    const instances = interleave(restStack.slice(0, length), postStack.slice(0, length));
    return instances;
  } else {
    return instancesStacks.flatMap(({ instances }) => instances);
  }
};

export interface ImageViewerActionsOptions {
  isPlaying?: boolean;
  layout?: Layout;
  speed?: number;
  toolbarPosition?: 'top' | 'bottom';
  brightness?: number;
  contrast?: number;
  minSpeed?: number;
  maxSpeed?: number;
  mode?: ImageViewerMode;
  sync?: Sync;
}

const useImageViewerActionsInternal = (study: Study, options?: ImageViewerActionsOptions) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const gridRef = React.useRef<HTMLDivElement>(null);

  const [toolbarPositionLocalStorage, setToolbarPositionLocalStorage] = useLocalStorage<
    'top' | 'bottom'
  >('image-viewer-toolbar-position', DEFAULT_TOOLBAR_POSITION);

  const isPlaying = options?.isPlaying ?? DEFAULT_IS_PLAYING;
  const isFullscreen = DEFAULT_IS_FULLSCREEN;
  const layout = options?.layout ?? DEFAULT_LAYOUT;
  const mode = options?.mode ?? DEFAULT_MODE;
  const brightness = options?.brightness ?? DEFAULT_BRIGHTNESS;
  const contrast = options?.contrast ?? DEFAULT_CONTRAST;
  const speed = options?.speed ?? DEFAULT_SPEED;
  const toolbarPosition =
    options?.toolbarPosition ?? toolbarPositionLocalStorage ?? DEFAULT_TOOLBAR_POSITION;
  const sync = options?.sync ?? DEFAULT_SYNC;
  const currentIndex = DEFAULT_CURRENT_INDEX;
  const isReportShowing = false;

  const initialState = React.useMemo(() => {
    const imageInstances = study.instances.filter((instance) =>
      isPlayableDicom(
        instance.dicom.transferSyntaxUID ?? undefined,
        instance.dicom.imageType ?? undefined,
      ),
    );
    const sortedInstances = P.orderBy(imageInstances, (instance) => instance.dicom.instanceNumber);
    const instancesStacks = getInitialInstancesStack(sortedInstances, mode);
    const hasStressInstances = study.instances.some(
      (instance) => instance.dicom.stage !== Stage.Unknown,
    );

    return {
      isPlaying,
      isFullscreen,
      layout,
      brightness,
      contrast,
      speed,
      toolbarPosition,
      mode,
      sync,
      isReportShowing,
      currentIndex,
      instances: sortedInstances,
      instancesStacks: getInitialInstancesStack(sortedInstances, mode),
      playerInstances: getInitialPlayerInstances(instancesStacks, mode),
      synchronizer: new Synchronizer(),
      hasStressInstances,
    };
  }, [
    isPlaying,
    isFullscreen,
    layout,
    brightness,
    contrast,
    speed,
    toolbarPosition,
    mode,
    sync,
    isReportShowing,
    currentIndex,
    study.instances,
  ]);

  const [state, methods] = useMethods(imageViewerMethods, initialState);

  // Sync the toolbar position from localstorage
  React.useEffect(() => {
    if (toolbarPositionLocalStorage !== state.toolbarPosition) {
      setToolbarPositionLocalStorage(state.toolbarPosition);
    }
  }, [setToolbarPositionLocalStorage, state.toolbarPosition, toolbarPositionLocalStorage]);

  useHotkey(' ', methods.playPause, { filterForms: true }, []); // Spacebar
  useHotkey('ArrowRight', methods.nextClip, { filterForms: true }, [state.layout]);
  useHotkey('ArrowLeft', methods.previousClip, { filterForms: true }, [state.layout]);
  useHotkey('ArrowDown', methods.nextFrame, { filterForms: true }, [state.layout]);
  useHotkey('ArrowUp', methods.previousFrame, { filterForms: true }, [state.layout]);
  useScroll((direction, coords) => {
    if (gridRef.current && insideBound(gridRef.current.getBoundingClientRect(), coords)) {
      methods.scroll(direction);
    }
  });

  useFullscreen(containerRef, state.isFullscreen, {
    onClose: () => methods.changeFullscreen(false),
  });

  const viewportPlayerInstances = React.useMemo(() => {
    return state.playerInstances.slice(
      state.currentIndex,
      state.currentIndex + layoutToCount(state.layout),
    );
  }, [state.currentIndex, state.layout, state.playerInstances]);

  return {
    ...state,
    ...methods,
    viewportPlayerInstances,
    containerRef,
    gridRef,
  };
};

export type ImageViewerActions = ReturnType<typeof useImageViewerActionsInternal>;

export const useImageViewerActions = (
  study: Study,
  options?: ImageViewerActionsOptions,
): ImageViewerActions => useImageViewerActionsInternal(study, options);
