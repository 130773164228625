import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ReportTemplate,
  ReportTemplateStatementSite,
  ReportTemplateVariable,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateReportTemplateStatementSitesMutation } from '../../../graphql/hooks/useUpdateReportTemplateStatementSitesMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { createModal } from '../../generic/Modal';
import { HookedStatementsManager } from './manager/HookedStatementsManager';

export const validationSchema = Yup.object({
  statementSites: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      statements: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          default: Yup.boolean().required(),
          value: Yup.string().required(),
        }),
      ),
    }),
  ),
});

interface Props {
  reportTemplate: ReportTemplate;
  onReportTemplateChange?: (reportTemplate: Partial<ReportTemplate>) => Promise<void>;
}

type FormValues = {
  currentReportTemplateStatementSiteId: string | null;
  statementSites: ReportTemplateStatementSite[];
  reportTemplateVariables: Record<string, ReportTemplateVariable>;
};

export const StatementSitesEditModal = createModal<Props>((props) => {
  const { reportTemplate, modal } = props;

  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateReportTemplateStatementSitesMutation();

  const defaultValues: FormValues = React.useMemo(
    () => ({
      currentReportTemplateStatementSiteId: reportTemplate.statementSites[0]?.id ?? null,
      statementSites: reportTemplate.statementSites,
      reportTemplateVariables: _.keyBy(reportTemplate.variables, 'id'),
    }),
    [reportTemplate],
  );

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = methods;

  const onSubmit = async (values: FormValues): Promise<void> => {
    const request = {
      organizationId: organization.id,
      reportTemplateId: reportTemplate.id,
      request: {
        reportTemplateStatementSites: values.statementSites,
      },
    };

    return mutation
      .mutateAsync(request)
      .then(async () => {
        successToast('Statement sites updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating statement sites: ${err.message}`);
      });
  };

  // Reinitialize the form when the default values change (eg when we save)
  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      size="6xl"
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Manage statements</Text>
          <Text fontSize="sm" fontWeight="normal" color="secondary">
            You can create, edit or delete statements here.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody minH="xl">
              <HookedStatementsManager />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button key="close" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
