import { Progress, Text } from '@chakra-ui/react';
import { ResultSet } from '@cubejs-client/core';
import { createColumnHelper, DataTable } from '@piccolohealth/ui';
import _ from 'lodash';
import React from 'react';
import { CubeQuery } from './CubeQuery';
import { CubeChartQuery } from './queries';

interface Props {
  query: CubeChartQuery;
  limit: number;
}

interface Item {
  id: string;
  label: string;
  value: number;
  percentage: number;
  color: string;
}

export interface ChartData {
  items: Item[];
  total: number;
}

const getChartData = (
  query: CubeChartQuery,
  resultSet: ResultSet<any>,
  limit: number,
): ChartData => {
  if (_.isEmpty(resultSet.seriesNames())) {
    return {
      items: [],
      total: 0,
    };
  }

  const rows = _.chain(resultSet.tablePivot())
    .map((row, index) => {
      return {
        id: `${row[query.chart.x] as string}-${index}`,
        label: !_.isNull(row[query.chart.x]) ? (row[query.chart.x] as string) : 'Unknown',
        value: _.parseInt(row[query.chart.y] as string),
        color: 'purple',
      };
    })
    .sortBy('value')
    .reverse()
    .take(limit)
    .value();

  const total = _.sumBy(rows, 'value');

  const items = _.map(rows, (row) => {
    return {
      ...row,
      percentage: _.round((row.value / total) * 100, 2),
    };
  });

  return {
    items,
    total,
  };
};

export const CubeTable = (props: Props) => {
  const { query, limit } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Item>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => <Text>{ps.row.original.label}</Text>,
      }),
      columnHelper.display({
        header: 'Reports',
        size: 60,
        cell: (ps) => <Text>{ps.row.original.value}</Text>,
      }),
      columnHelper.display({
        header: 'Percent',
        cell: (ps) => (
          <Progress
            value={ps.row.original.percentage}
            colorScheme="purple"
            width="100%"
            rounded="xl"
          />
        ),
      }),
    ];
  }, []);

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        const chartData = getChartData(query, resultSet, limit);
        return <DataTable columns={columns} data={chartData.items} size="sm" />;
      }}
    />
  );
};
