import { Box, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import React from 'react';

export type DescriptionItem = {
  title: string;
  content: JSX.Element;
};

interface Props extends SimpleGridProps {
  items: DescriptionItem[];
}

export const DescriptionList = (props: Props) => {
  const { items, ...rest } = props;

  return (
    <SimpleGrid
      columns={2}
      rowGap={4}
      columnGap={4}
      templateColumns="max-content auto"
      alignItems="start"
      {...rest}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Box fontSize="sm" fontWeight="semibold">
            {item.title}
          </Box>
          <Box>{item.content}</Box>
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};
