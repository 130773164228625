import {
  AlertProps,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Icon,
  IconProps,
  Square,
} from '@chakra-ui/react';
import React from 'react';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';

export type Status = 'success' | 'warning' | 'error' | 'info' | 'empty';

export interface ResultProps extends Omit<AlertProps, 'status'> {
  status: Status;
  title?: string;
  description?: string | React.ReactNode;
  extra?: React.ReactNode;
}

const getStatusIconProps = (status: Status) => {
  switch (status) {
    case 'success':
      return { icon: FaCheckCircle, iconColor: 'green.400', bgColor: 'green.50' };
    case 'warning':
      return { icon: FaExclamationTriangle, iconColor: 'orange.400', bgColor: 'orange.50' };
    case 'error':
      return { icon: FaTimesCircle, iconColor: 'red.400', bgColor: 'red.50' };
    case 'info':
      return { icon: FaInfoCircle, iconColor: 'blue.400', bgColor: 'blue.50' };
    case 'empty':
      return { icon: FaSearch, iconColor: 'gray.400', bgColor: 'gray.50' };
  }
};

const StatusIcon = (props: { status: Status } & IconProps) => {
  const { status, ...rest } = props;

  const { icon, iconColor, bgColor } = getStatusIconProps(status);

  return (
    <Square size="20" rounded="3xl" bg={bgColor}>
      <Icon as={icon} color={iconColor} boxSize="10" {...rest} />
    </Square>
  );
};

export const Result = (props: ResultProps) => {
  const { status, title, description, extra, ...rest } = props;
  return (
    <Alert
      p={16}
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bg="transparent"
      border={0}
      {...rest}
    >
      <StatusIcon status={status} boxSize="8" />
      <AlertTitle mt={2} fontSize="md" color="gray.700">
        {title ?? 'Error'}
      </AlertTitle>
      <AlertDescription mt={2} maxWidth="md" color="gray.700">
        {description}
      </AlertDescription>
      {extra && <Box mt={4}>{extra}</Box>}
    </Alert>
  );
};
