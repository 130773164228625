import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { inflection, P } from '@piccolohealth/util';
import { intersperse, Report } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import * as Yup from 'yup';
import { validateSchema } from '../../../utils/validation';
import { Divider } from '../../generic/Divider';

interface Props {
  report: Report;
  schema: Yup.BaseSchema<any>;
}

export const ReportStatusWarnings = (props: Props) => {
  const { report, schema } = props;

  const errors = React.useMemo(() => {
    const values = P.keyBy(report.variables, (variable) => variable.alias as string) ?? {};

    return validateSchema(schema, values) ?? [];
  }, [report, schema]);

  if (_.isEmpty(errors)) {
    return (
      <Alert status="success">
        <AlertIcon />
        <AlertTitle>There were no issues found</AlertTitle>
      </Alert>
    );
  } else {
    const data = _.map(errors, (error) => {
      return {
        title: inflection
          .titleize(inflection.underscore(_.get(error.params as {}, 'label', error.path ?? '')))
          .replace('.value', ''),
        message: error.message,
        icon: <Icon as={FaExclamationTriangle} fontSize="28px" />,
      };
    });

    const items = _.map(data, ({ title, message }, index) => {
      return (
        <HStack key={index} spacing={4} fontSize="sm">
          <Box>
            <Text fontWeight="bold">{title}</Text>
            <Text>{message}</Text>
          </Box>
        </HStack>
      );
    });

    return (
      <Alert status="warning">
        <AlertIcon />
        <Box w="full">
          <AlertDescription>
            {intersperse(items, () => (
              <Divider />
            ))}
          </AlertDescription>
        </Box>
      </Alert>
    );
  }
};
