import { Flex, Heading, Icon, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { Query } from '@cubejs-client/core';
import { getPercentChange } from '@piccolohealth/echo-common';
import React from 'react';
import { FaArrowDown, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import { TimeRange } from '../../../hooks/useTimeRangeCubeQuery';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: Query | Query[];
  timeRange: TimeRange;
}

const changeProperties = (previousValue: number, currentValue: number) => {
  const changeValue = getPercentChange(previousValue, currentValue);

  if (previousValue === currentValue) {
    return {
      changeColorScheme: 'gray',
      changeIcon: FaArrowRight,
      changeValue,
    };
  } else if (previousValue > currentValue) {
    return {
      changeColorScheme: 'red',
      changeIcon: FaArrowDown,
      changeValue,
    };
  } else {
    return {
      changeColorScheme: 'green',
      changeIcon: FaArrowUp,
      changeValue,
    };
  }
};

export const CubeValue = (props: Props) => {
  const { query, timeRange } = props;

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        const [current, previous] = resultSet
          .series({ fillMissingDates: true })
          .map(({ title, series }) => {
            return {
              name: title,
              value: series[0].value,
            };
          });

        const { changeValue, changeIcon, changeColorScheme } = changeProperties(
          previous.value,
          current.value,
        );

        return (
          <Flex direction="column" w="100%">
            <Stack direction="row" align="center" spacing={4}>
              <Heading lineHeight={1.1} size="2xl">
                {current.value}
              </Heading>
            </Stack>

            <Stack direction="row" align="center" spacing={4}>
              <Text>Past {timeRange}</Text>
              <Tag
                mt="1"
                fontWeight="semibold"
                size="md"
                variant="subtle"
                colorScheme={changeColorScheme}
              >
                <Icon as={changeIcon} />
                <TagLabel ml="2">{changeValue}%</TagLabel>
              </Tag>
            </Stack>
          </Flex>
        );
      }}
    />
  );
};
