import { OrganizationMinimal, PiccoloError } from '@piccolohealth/echo-common';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useAppContext } from './useAppContext';

export const useOrganizationRestQuery = (shortOrganizationId: string | undefined) => {
  const { config } = useAppContext();

  return useQuery<AxiosResponse<OrganizationMinimal>, PiccoloError, OrganizationMinimal, string[]>({
    queryKey: ['organization-rest'],
    queryFn: () => {
      return axios(`${config.api.url}/organizations/${shortOrganizationId}/upload`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    select: (resp) => {
      return resp.data;
    },
  });
};
