import { Image, Box, Button, Center, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { PiccoloError } from '@piccolohealth/echo-common';
import {
  FileUploadControl,
  FileUploadItemsProgress,
  ScrollAreaAutosize,
  Spin,
  useFileUpload,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import axios from 'axios';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Error } from '../../components/generic/Error';
import { PiccoloIcon } from '../../components/generic/PiccoloIcon';
import { useAppContext } from '../../hooks/useAppContext';
import { useOrganizationRestQuery } from '../../hooks/useOrganizationRestQuery';

export const Upload = () => {
  const { config } = useAppContext();
  const { shortOrganizationId } = useParams();
  const { data: organization, isLoading, error } = useOrganizationRestQuery(shortOrganizationId);

  const sendFile = React.useCallback(
    async (
      file: File,
      onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
    ) => {
      if (!organization?.id) {
        return;
      }

      const signedUrlResp = await axios(
        `${config.api.url}/organizations/${organization.id}/upload-instance`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: { filename: file.name },
        },
      );
      const { signedUrl, blobUri } = await signedUrlResp.data;

      await axios(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/octet-stream' },
        data: await file.arrayBuffer(),
        onUploadProgress: (event) => {
          onProgress({
            percentage: (event.loaded / event.total) * 100,
            total: event.total,
            loaded: event.loaded,
          });
        },
      });

      await axios(`${config.api.url}/organizations/${organization.id}/upload-instance-callback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { blobUri, source: 'external' },
      });

      onProgress({ percentage: 100 });
    },
    [config.api.url, organization?.id],
  );

  const fileUploader = useFileUpload({
    sendFile,
    concurrency: 12,
    autoStart: true,
  });

  if (isLoading) {
    return (
      <Center h="full">
        <Spin />
      </Center>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!organization) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'OrganizationNotFound',
            message: 'Organization not found',
          })
        }
      />
    );
  }

  return (
    <Stack w="full" h="full">
      <Center h="full" pos="relative">
        <HStack
          flexShrink={0}
          spacing={1}
          fontSize="24"
          lineHeight="normal"
          userSelect="none"
          pos="absolute"
          top={6}
          left={6}
          color="#4c3dc4"
        >
          <PiccoloIcon />
          <Text fontWeight="bold" color="black">
            <Link to="https://piccolohealth.com" target="blank">
              Piccolo Health
            </Link>
          </Text>
        </HStack>

        <Stack w="full" p={4} spacing={6} align="center">
          <Stack spacing={2} align="center" textAlign="center" w="md">
            <Box py={4}>
              {organization.logo ? (
                <Image src={organization.logo} ignoreFallback maxH="64px" />
              ) : (
                <PiccoloIcon />
              )}
            </Box>
            <Heading size="xl">{organization.name}</Heading>
            <Stack spacing={0}>
              <Text color="secondary">
                {[organization.addressLineOne, organization.addressLineTwo]
                  .filter(Boolean)
                  .join(' | ')}
              </Text>
              <Text color="secondary">
                {[organization.email, organization.phone].filter(Boolean).join(' | ')}
              </Text>
            </Stack>
          </Stack>

          <Stack
            rounded="xl"
            shadow="sm"
            layerStyle="bordered"
            py={12}
            spacing={8}
            w="full"
            maxW="xl"
          >
            <Stack px={12} align="center" spacing={2}>
              <Heading size="md">Upload DICOM Files</Heading>
              <Text color="secondary" textAlign="center">
                The files will be uploaded to {organization.name}
              </Text>
            </Stack>

            <Stack spacing={4} w="full" align="start">
              <Box w="full" px={12}>
                {P.isEmpty(fileUploader.files) && (
                  <FileUploadControl
                    multiple={true}
                    files={fileUploader.files}
                    onFilesChange={fileUploader.onFilesChange}
                    status={fileUploader.status}
                  />
                )}
              </Box>
              <ScrollAreaAutosize w="full" maxH="xs" overflowY="hidden" layerStyle="fade">
                <Box px={12} w="full">
                  <FileUploadItemsProgress files={Object.values(fileUploader.fileProgress)} />
                </Box>
              </ScrollAreaAutosize>
              {!P.isEmpty(fileUploader.files) && (
                <Flex w="full" justifyContent="end" px={12}>
                  <Button variant="outline" onClick={() => fileUploader.reset()}>
                    Reset
                  </Button>
                </Flex>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Center>
    </Stack>
  );
};
