import { Button, Menu, MenuButton, Text } from '@chakra-ui/react';
import React from 'react';
import { PiccoloIcon } from '../generic/PiccoloIcon';

interface Props {
  text: string;
  icon?: JSX.Element;
}

export const BrandMenu = (props: React.PropsWithChildren<Props>) => {
  const { icon, text, children, ...rest } = props;

  return (
    <Menu>
      <MenuButton
        as={Button}
        size="lg"
        variant="link"
        color="white"
        _active={{ color: 'white' }}
        leftIcon={<PiccoloIcon boxSize="6" />}
        rightIcon={icon}
        {...rest}
      >
        <Text display={{ sm: 'none', md: 'block' }}>{text}</Text>
      </MenuButton>
      {children}
    </Menu>
  );
};
