import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../context/ConfigContext';
import { useOrganization } from '../context/OrganizationContext';
import { useUser } from '../context/UserContext';
import { useGetCss } from './useGetCss';
import { useToast } from './useToast';

const useGetContext = () => {
  const queryClient = useQueryClient();
  const auth = useAuth0();
  const config = useConfig();
  const user = useUser();
  const organization = useOrganization();
  const toast = useToast();
  const location = useLocation();
  const css = useGetCss();

  return {
    ...config,
    ...toast,
    location,
    auth,
    user,
    organization,
    queryClient,
    css,
  };
};

export const useAppContext: () => ReturnType<typeof useGetContext> = () => {
  return useGetContext();
};
