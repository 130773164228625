import {
  Button,
  ButtonGroup,
  MenuItemOption,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PERMISSIONS, Report, ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import * as yup from 'yup';
import { useUpdateReportStatusMutation } from '../../../graphql/hooks/useUpdateReportStatusMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { createModal, showModal } from '../../generic/Modal';
import { ReportStatusWarnings } from './ReportStatusWarnings';

interface UnreportedModalProps {
  report: Report;
}

const UnreportedModal = createModal<UnreportedModalProps>((props) => {
  const { modal, report } = props;

  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateReportStatusMutation();

  const onClick = React.useCallback(() => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId: report.id,
        updateReportStatusRequest: {
          status: ReportStatus.Unreported,
        },
      })
      .then(() => {
        successToast('Report marked as unreported');
        modal.hide();
      })
      .catch(() => {
        errorToast('Error updating report status');
      });
  }, [mutation, successToast, errorToast, modal, organization.id, report.id]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to mark the report as unreported?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <ReportStatusWarnings schema={unreportedWarningsSchema()} report={report} />
            <Text>Are you sure you want to continue?</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button onClick={modal.hide}>Close</Button>
            <Button
              onClick={onClick}
              isLoading={mutation.isLoading}
              colorScheme="purple"
              data-pw="reportStatusUpdateModalSubmitButton"
            >
              OK
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export const unreportedWarningsSchema = () => yup.object();

export const UnreportedMenuItemOption = (props: { report: Report }) => {
  const { report } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsReport).value;
  const isChecked = report.status === ReportStatus.Unreported;
  const isDisabled = !hasPermission || isChecked;

  return (
    <>
      <MenuItemOption
        onClick={() => showModal(UnreportedModal, { report })}
        isDisabled={isDisabled}
        isChecked={isChecked}
        data-pw={`reportStatusUpdateMenuItemOption-${ReportStatus.Unreported}`}
      >
        Unreported
      </MenuItemOption>
    </>
  );
};
