import { Button, ButtonGroup, ModalFooter } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateIntegrationsMutation } from '../../../../graphql/hooks/useUpdateIntegrationMutation';
import { useAppContext } from '../../../../hooks/useAppContext';
import { FormSection } from '../../../forms/FormSection';
import { HookedFormItem } from '../../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../../../forms/hookform/HookedSwitch';
import { Divider } from '../../../generic/Divider';
import { HealthLinkProviderMappingsControl } from './HealthLinkProviderMappingControl';

const validationSchema = Yup.object({
  enabled: Yup.boolean().required(),
  providerMappings: Yup.array().of(
    Yup.object({
      providerNumber: Yup.string().required(),
      userId: Yup.string().required(),
    }),
  ),
  hl7Overrides: Yup.object({
    clinicalArea: Yup.string().nullable(),
  }).nullable(),
});

type FormValues = {
  enabled: boolean;
  providerMappings: {
    providerNumber: string;
    userId: string;
  }[];
  hl7Overrides?: {
    clinicalArea?: string | null;
  } | null;
};

interface Props {
  initialValues: FormValues;
  hide: () => void;
}

export const HealthLinkIntegrationForm = (props: Props) => {
  const { initialValues, hide } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateIntegrationsMutation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          updateIntegrationsRequest: {
            healthLink: values,
          },
        })
        .then(() => {
          reset(values);
          successToast(`HealthLink integration updated successfully`);
          hide();
        })
        .catch((error) => {
          errorToast(`Error editing HealthLink integration: ${error.message}`);
        });
    },
    [mutation, hide, reset, successToast, errorToast, organization.id],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormSection>
          <HookedFormItem label="Enabled" name="enabled" layout="horizontal">
            <HookedSwitch name="enabled" />
          </HookedFormItem>
          <Divider />
          <HookedFormItem
            label="Provider mappings"
            name="providerMappings"
            helperText="Link provider numbers to users"
          >
            <HealthLinkProviderMappingsControl name="providerMappings" />
          </HookedFormItem>
        </FormSection>
        <Divider />

        <FormSection heading="HL7 overrides">
          <HookedFormItem
            label="Clinical area (ORU.24)"
            name="hl7Overrides.clinicalArea"
            helperText="Optionally override the 'Clinical Area' in HL7 messages"
          >
            <HookedInput name="hl7Overrides.clinicalArea" placeholder="Enter a clinical area" />
          </HookedFormItem>
        </FormSection>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <HookedSubmitButton key="submit">Save</HookedSubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
