import { Box, Input as ChakraInput, Input, InputProps, useStyleConfig } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { getReportTemplateVariableForVariable } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useController } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { useTextWidth } from '../../../../hooks/useTextWidth';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { NodeSizeSettings } from '../../common/nodes/NodeSize';
import { NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import { NodeTypographyProps, NodeTypographySettings } from '../../common/nodes/NodeTypography';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { ReportVariableRenderingError } from '../../common/ReportVariableRenderingError';
import { useSSRNode } from '../../hooks/useSSRNode';
import { theme } from '../../../../theme/theme';
import { P } from '@piccolohealth/util';

interface InputWithUnitsProps extends InputProps {
  variableId: string;
  name: string;
}

const InputWithUnits = (props: InputWithUnitsProps) => {
  const { variableId, name, ...rest } = props;
  const { isDisabled } = useReport();
  const { compiledTemplate } = useCompiledTemplate(`{{withPlaceholder ${variableId} "-"}}`);

  return <Input value={compiledTemplate} isDisabled={isDisabled} {...rest} />;
};

const HookedInputWithUnits = (props: InputWithUnitsProps) => {
  const { variableId, name, ...rest } = props;
  const { reportTemplate, isDisabled } = useReport();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { field } = useController({ name });

  const reportTemplateVariable = getReportTemplateVariableForVariable(reportTemplate, variableId);

  const { recalculateWidth, width } = useTextWidth({
    ref: inputRef,
  });

  const baseInputStyles = useStyleConfig('Input', rest) as any;

  React.useEffect(() => {
    recalculateWidth();
  }, [field.value, recalculateWidth]);

  if (!reportTemplateVariable) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Unable to find variable ${variableId} in input component`}
      />
    );
  }

  if (reportTemplateVariable.__typename !== 'ReportTemplateStaticVariable') {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Variable ${variableId} is not supported by input component`}
      />
    );
  }

  const afterNode =
    !_.isNil(reportTemplateVariable.units) && (inputRef?.current?.value?.length ?? 0) > 0
      ? {
          ...rest,
          px: P.get(theme, baseInputStyles.field['--input-padding']),
          fontSize: rest.fontSize ?? P.get(theme, baseInputStyles.field['--input-font-size']),
          pointerEvents: 'none',
          display: 'flex',
          position: 'absolute',
          height: '100%',
          left: width,
          alignItems: 'center',
          justifyItems: 'center',
          content: `"${reportTemplateVariable.units}"`,
          lineHeight: 'normal',
        }
      : {};

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <Box
      position="relative"
      w="full"
      display="flex"
      overflow="hidden"
      sx={{
        _after: afterNode,
      }}
    >
      <HookedInput
        ref={inputRef}
        name={name}
        isDisabled={isDisabled}
        onKeyDown={onKeyDown}
        data-pw={`inputVariableControl-${variableId}`}
        {...rest}
      />
    </Box>
  );
};

export interface Props extends BaseNodeProps, NodeSpacingProps, NodeTypographyProps {
  variableId: string;
  size: 'sm' | 'md' | 'lg';
}

export const InlineInput = (props: Props) => {
  const { variableId, padding, margin, ssr } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const name = `variables.${props.variableId}.value`;

  const nodeStyles = _.pickBy(
    {
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      lineHeight: props.lineHeight,
    },
    _.identity,
  );

  let content;

  if (ssr) {
    content = (
      <InputWithUnits
        name={name}
        variableId={variableId}
        variant="unstyled"
        w="full"
        size={props.size}
        p={padding}
        m={margin}
        sx={nodeStyles}
      />
    );
  } else {
    content = (
      <HookedInputWithUnits
        name={name}
        variableId={variableId}
        variant="unstyled"
        w="full"
        size={props.size}
        p={padding}
        m={margin}
        sx={nodeStyles}
      />
    );
  }

  return (
    <Box ref={(ref) => connect(drag(ref))} w="full">
      {content}
    </Box>
  );
};

const InlineInputSettings = () => {
  const {
    actions: { setProp },
    variableId,
    size,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title="Inline input">
        <FormItem id="variableId" label="Variable">
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id="size" label="Size">
          <ChakraInput
            value={size}
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
            size="sm"
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title="Spacing">
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title="Typography">
        <NodeTypographySettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineInput.defaultProps = {
  variableId: 'patientName',
  size: 'sm' as const,
  ...NodeSpacingSettings.defaultProps,
  ...NodeSizeSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
};

InlineInput.craft = {
  name: 'InlineInput',
  props: InlineInput.defaultProps,
  related: {
    settings: InlineInputSettings,
  },
};
