import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedResetButton } from '../../../components/forms/hookform/HookedResetButton';
import { HookedSelect } from '../../../components/forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { Content } from '../../../components/generic/Content';
import { Divider } from '../../../components/generic/Divider';
import { useDeleteOrganizationMutation } from '../../../graphql/hooks/useDeleteOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface FormValues {
  organizationId: string;
}

export const Offboard = () => {
  const { user, successToast, errorToast } = useAppContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useDeleteOrganizationMutation();

  const cancelRef = React.useRef(null);

  const initialValues = {
    organizationId: '',
  };

  const validationSchema = Yup.object({
    organizationId: Yup.string().required(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    await mutation
      .mutateAsync(values)
      .then(() => {
        successToast('Organization deleted successfully');
        methods.reset();
        onClose();
      })
      .catch((err) => {
        errorToast(`Error deleting organization: ${err.message}`);
      });
  };

  const organizationOptions = user.organizationMemberships.map((membership) => ({
    label: membership.name,
    value: membership.organizationId,
    raw: membership.organizationId,
  }));

  return (
    <Content title="Offboard">
      <Box maxW="400px" p={4}>
        <FormProvider {...methods}>
          <form>
            <FormSection heading="Delete an organization">
              <HookedFormItem label="Organization" name="organizationId">
                <HookedSelect name="organizationId" options={organizationOptions} />
              </HookedFormItem>
            </FormSection>
            <Divider />
            <HStack alignItems="center">
              <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Organization
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You cannot undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <HookedSubmitButton
                        colorScheme="red"
                        ml={3}
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      >
                        Delete
                      </HookedSubmitButton>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
              <HookedResetButton>Reset</HookedResetButton>
              <Button colorScheme="purple" size="sm" onClick={onOpen}>
                Submit
              </Button>
            </HStack>
          </form>
        </FormProvider>
      </Box>
    </Content>
  );
};
