import { Button } from '@chakra-ui/react';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { JsonTree } from '../../../components/generic/JsonTree';

export const AuthorizationDebug = () => {
  const { auth, user } = useAppContext();
  const [accessToken, setAccessToken] = React.useState('');

  React.useEffect(() => {
    const fn = async () => {
      const accessToken = await auth.getAccessTokenSilently();
      setAccessToken(accessToken);
    };
    fn();
  }, [accessToken, auth]);

  return (
    <div>
      <Button colorScheme="purple" onClick={() => navigator.clipboard.writeText(accessToken)}>
        Copy access token
      </Button>
      <JsonTree src={user} collapsed={false} />
    </div>
  );
};
