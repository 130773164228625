import { BaseSchema, ValidationError } from 'yup';

export const validateSchema = (schema: BaseSchema<any>, values: any): ValidationError[] => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return [];
  } catch (error) {
    return error.inner;
  }
};
