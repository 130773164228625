import {
  Button,
  ButtonGroup,
  MenuItemOption,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PERMISSIONS, Report, ReportStatus, User } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useUpdateReportStatusMutation } from '../../../graphql/hooks/useUpdateReportStatusMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { createModal, showModal } from '../../generic/Modal';
import { ReportStatusWarnings } from './ReportStatusWarnings';

export const awaitingReviewWarningsSchema = (user: User) =>
  Yup.object().shape({
    sex: Yup.object().shape({ value: Yup.string().nullable().required('No sex selected') }),
    patientName: Yup.object().shape({
      value: Yup.string().nullable().required('No Patient name entered'),
    }),
    patientId: Yup.object().shape({
      value: Yup.string().nullable().required('No Patient ID entered'),
    }),
    dob: Yup.object().shape({
      value: new Yup.DateSchema().nullable().required('No date of birth entered'),
    }),
    sonographer: Yup.object()
      .shape({
        value: Yup.array().nullable().min(1, 'No reporting sonographer selected'),
      })
      .test('sonographerPresent', ({ value }, context) => {
        const values = value ?? [];

        if (_.isEmpty(values) || _.includes(values, user.name)) {
          return true;
        }

        return context.createError({
          message: `You (${user.name}) are not in the list of assigned sonographers (${values.join(
            ',',
          )})`,
          path: 'sonographer',
        });
      })
      .nullable(),
  });

interface AwaitingReviewModalProps {
  report: Report;
}

const AwaitingReviewModal = createModal<AwaitingReviewModalProps>((props) => {
  const { modal, report } = props;
  const { organization, successToast, errorToast, user } = useAppContext();
  const mutation = useUpdateReportStatusMutation();

  const onClick = React.useCallback(() => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId: report.id,
        updateReportStatusRequest: {
          status: ReportStatus.AwaitingReview,
        },
      })
      .then(() => {
        successToast('Report marked as awaiting review');
        modal.hide();
      })
      .catch(() => {
        errorToast('Error updating report status');
      });
  }, [mutation, successToast, errorToast, modal, organization.id, report.id]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to mark the report as awaiting review?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <ReportStatusWarnings schema={awaitingReviewWarningsSchema(user)} report={report} />
            <Text>Are you sure you want to continue?</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button onClick={modal.hide}>Close</Button>
            <Button
              onClick={onClick}
              isLoading={mutation.isLoading}
              colorScheme="purple"
              data-pw="reportStatusUpdateModalSubmitButton"
            >
              OK
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export const AwaitingReviewMenuItemOption = (props: { report: Report }) => {
  const { report } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsReport).value;

  const isChecked = report.status === ReportStatus.AwaitingReview;
  const isDisabled = !hasPermission || isChecked;

  return (
    <MenuItemOption
      onClick={() => showModal(AwaitingReviewModal, { report })}
      isDisabled={isDisabled}
      isChecked={isChecked}
      data-pw={`reportStatusUpdateMenuItemOption-${ReportStatus.AwaitingReview}`}
    >
      Awaiting review
    </MenuItemOption>
  );
};
