import { Duration } from 'luxon';

export * from 'luxon';

export const timezoneList = [
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'America/Toronto',
];

export const localeList = ['en-au', 'en-us'];

// https://github.com/moment/luxon/issues/1134#issue-1128331010
export const humanizeDuration = (dur: Duration, smallestUnit = 'seconds'): string => {
  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
  const smallestIdx = units.indexOf(smallestUnit);
  const entries = Object.entries(
    dur
      .shiftTo(...(units as any))
      .normalize()
      .toObject(),
  ).filter(([_unit, amount], idx) => amount > 0 && idx <= smallestIdx);

  const dur2 = Duration.fromObject(
    entries.length === 0 ? { [smallestUnit]: 0 } : Object.fromEntries(entries),
  );
  return dur2.toHuman();
};
