import { useTheme } from '@chakra-ui/react';
import { Query, ResultSet } from '@cubejs-client/core';
import { DateTime } from '@piccolohealth/util';
import { LooseObject } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { ChakraColorMap, getChakraColor } from '../../../utils/colors';
import { BarChart, ChartData } from '../../charts/BarChart';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: Query;
  layout?: 'vertical' | 'horizontal';
}

const dateFormats = {
  hour: 'p EEEEEE',
  day: 'dd/MM',
  week: 'dd/MM',
  month: 'MM/yy',
};

const getChartData = (
  query: Query,
  resultSet: ResultSet<any>,
  colors: ChakraColorMap,
): ChartData[] => {
  const seriesNames = resultSet.seriesNames();

  if (_.isEmpty(seriesNames)) {
    return [];
  }

  const chart = (query as any).chart;

  const chartPivot = resultSet.chartPivot();

  const granularity: string = _.head(query.timeDimensions)?.granularity || 'day';

  const data = _.chain(chartPivot)
    .map((row) => {
      const y = _.reduce(
        resultSet.seriesNames(),
        (acc, series) => {
          const info: LooseObject = _.reduce(
            resultSet.query().dimensions,
            (acc, curr, index) => {
              return {
                [curr]: series.yValues[index],
                ...acc,
              };
            },
            {},
          );

          const name = info[chart.x];

          if (name === null) {
            return acc;
          }

          const value = row[series.key];
          const color = getChakraColor(colors, info[chart.color]);

          return {
            [name]: {
              value,
              color,
            },
            ...acc,
          };
        },
        {},
      );

      return {
        x: DateTime.fromISO(row.x).toFormat(_.get(dateFormats, granularity)),
        y,
      };
    })
    .value();

  return data;
};

export const CubeBarTimeChart = (props: Props) => {
  const { query, layout } = props;

  const theme = useTheme();

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        return (
          <BarChart chartData={getChartData(query, resultSet, theme.colors)} layout={layout} />
        );
      }}
    />
  );
};
