import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import React from 'react';
import { Empty } from '@piccolohealth/ui';
import { chartsTheme } from './ChartsTheme';

interface Props {
  chartData: ChartData[];
  layout?: 'vertical' | 'horizontal';
}

export interface ChartData {
  x: string;
  y: {
    [key: string]: {
      value: number;
      color: string;
    };
  };
}

export const BarChart = (props: Props) => {
  const { chartData, layout } = props;

  const data = _.map(chartData, (item) => {
    const yValues = _.mapValues(item.y, (x) => x.value);
    return {
      x: item.x,
      ...yValues,
    };
  });
  const colors = _.mapValues(_.head(chartData)?.y, (v) => v.color);
  const keys = _.keys(_.head(chartData)?.y);
  const xAxisTicks =
    data.length > 20 ? _.map(data, (d) => d.x).filter((_x, i) => i % 2 === 0) : undefined;

  if (_.isEmpty(data)) {
    return <Empty />;
  }

  return (
    <ResponsiveBar
      layout={layout}
      data={data}
      keys={keys}
      indexBy="x"
      colors={({ id }) => colors[id]}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.0]] }}
      borderRadius={4}
      borderWidth={1}
      innerPadding={4}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        format: (e) => Math.floor(e) === e && e,
      }}
      axisBottom={{
        tickValues: xAxisTicks,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      theme={chartsTheme}
    />
  );
};
