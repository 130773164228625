import { Stack, StackDirection, StackProps, Text, Tooltip } from '@chakra-ui/react';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';

interface Props extends StackProps {
  date: DateTime;
  format?: 'SHORT' | 'LONG' | Intl.DateTimeFormatOptions;
  direction?: StackDirection;
  showHumanized?: boolean;
  showTooltip?: boolean;
  tooltip?: () => React.ReactNode;
}

const getFormat = (format: Props['format']): Intl.DateTimeFormatOptions => {
  if (!P.isString(format) && !P.isNil(format)) {
    return format;
  }

  switch (format) {
    case 'SHORT':
      return DateTime.DATETIME_SHORT;
    case 'LONG':
      return DateTime.DATETIME_FULL;
    default:
      return DateTime.DATETIME_SHORT;
  }
};

export const FancyDate = (props: Props) => {
  const { date, format, direction, showHumanized, showTooltip, tooltip, ...rest } = props;
  const datePrime = DateTime.fromISO(date.toString());
  const absolute = datePrime.toLocaleString(getFormat(format));
  const timestamp = datePrime.toLocaleString(getFormat('LONG'));

  return (
    <Tooltip
      label={tooltip ? tooltip() : <Text>{timestamp}</Text>}
      isDisabled={showTooltip ?? true}
    >
      <Stack spacing={1} direction={direction} {...rest}>
        <Text>{absolute}</Text>
        {(showHumanized ?? true) && <Text color="secondary">{datePrime.toRelative()}</Text>}
      </Stack>
    </Tooltip>
  );
};
