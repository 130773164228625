import {
  chakra,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  StackProps,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { AutoCompleteOption, useAutoComplete } from '../hooks/useAutoComplete';
import { FloatingPopover } from './FloatingPopover';
import { ScrollAreaAutosize } from './ScrollArea';
import {
  Footer,
  Header,
  Loading,
  NoOptions,
  SelectContainer,
  SelectItem,
  SelectStatus,
  TextOption,
  TextValue,
} from './Select';

export type { AutoCompleteOption };

export interface AutoCompleteComponents<A> {
  Loading?: () => React.ReactElement | null;
  Value?: (props: {
    option: AutoCompleteOption<A>;
    onRemove: () => void;
    index: number;
  }) => React.ReactElement | null;
  NoOptions?: () => React.ReactElement | null;
  Option?: (props: { option: AutoCompleteOption<A> }) => React.ReactElement | null;
  Header?: (props: StackProps) => React.ReactElement | null;
  Footer?: (props: StackProps) => React.ReactElement | null;
}

export const AutoCompleteComponents: Required<AutoCompleteComponents<any>> = {
  Loading,
  NoOptions,
  Option: TextOption,
  Value: TextValue,
  Header,
  Footer,
};

export interface AutoCompleteProps<A> {
  options: AutoCompleteOption<A>[];
  value: string;
  variant?: InputProps['variant'];
  size?: InputProps['size'];
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  showArrow?: boolean;
  components?: AutoCompleteComponents<A>;
  onChange: (value: string) => void;
  onInputChange: (value: string) => void;
  onOpen?: () => void;
}

export const AutoComplete = <A extends unknown>(props: AutoCompleteProps<A>) => {
  const {
    options,
    value,
    placeholder,
    isLoading,
    isDisabled,
    variant = 'outline',
    size = 'sm',
    showArrow = true,
    components,
    onChange,
    onInputChange,
    onOpen,
    ...rest
  } = props;

  const autocomplete = useAutoComplete<A>({
    options,
    value,
    onChange,
    onInputChange,
    onOpen,
  });

  const Components: Required<AutoCompleteComponents<A>> = {
    Loading: AutoCompleteComponents.Loading,
    NoOptions: AutoCompleteComponents.NoOptions,
    Option: AutoCompleteComponents.Option,
    Value: AutoCompleteComponents.Value,
    Header: () => <></>,
    Footer: () => <></>,
    ...props.components,
  };

  return (
    <chakra.div {...autocomplete.menuProps()} w="full" {...rest}>
      <FloatingPopover
        open={autocomplete.isOpen}
        placement="bottom-start"
        isRoot={false}
        trigger="focus"
        render={() => (
          <SelectContainer>
            <Components.Header />
            <ScrollAreaAutosize maxH="xs" overflow="auto">
              <chakra.ul p={1.5} data-pw="menu">
                {isLoading && P.isEmpty(autocomplete.options) && <Components.Loading />}
                {!isLoading && P.isEmpty(autocomplete.options) && <Components.NoOptions />}
                {autocomplete.options.map((option) => (
                  <SelectItem key={option.value} {...autocomplete.optionProps(option)}>
                    <Components.Option option={option} />
                  </SelectItem>
                ))}
              </chakra.ul>
            </ScrollAreaAutosize>
            <Components.Footer />
          </SelectContainer>
        )}
      >
        <InputGroup variant={variant} size={size} w="full" rounded="md" bg="white" role="group">
          <Input
            {...autocomplete.inputProps()}
            disabled={isDisabled}
            placeholder={
              !autocomplete.value || autocomplete.value?.length === 0 ? placeholder : undefined
            }
            data-pw="input"
            size={size}
          />
          {showArrow && (
            <InputRightElement pointerEvents="none">
              <SelectStatus isLoading={isLoading} />
            </InputRightElement>
          )}
        </InputGroup>
      </FloatingPopover>
    </chakra.div>
  );
};
