import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';
import React from 'react';

interface Props {
  chartData: ChartData[];
  units?: string;
}

export interface ChartData {
  id: string;
  label: string;
  value: number;
  color: string;
}

const colors = [
  '#718096',
  '#E53E3E',
  '#DD6B20',
  '#D69E2E',
  '#38A169',
  '#319795',
  '#3182CE',
  '#00B5D8',
  '#805AD5',
  '#D53F8C',
];

export const PieChart = (props: Props) => {
  const { chartData } = props;

  return (
    <ResponsivePie
      data={chartData}
      colors={_.head(chartData)?.color ? { datum: 'data.color' } : colors}
      arcLinkLabel={(d) => `${d.label}: ${d.value}`}
      margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
      animate={true}
      activeOuterRadiusOffset={8}
      borderWidth={2}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      innerRadius={0.6}
      padAngle={3}
      enableArcLinkLabels={false}
      fit={false}
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 40,
          itemWidth: 100,
          itemHeight: 20,
          itemsSpacing: 0,
          symbolSize: 20,
          itemDirection: 'left-to-right',
        },
      ]}
    />
  );
};
