import { HStack, Stack, Text } from '@chakra-ui/react';
import { DateTime } from '@piccolohealth/util';
import { Instance, PERMISSIONS, renderDicomView, Stage, View } from '@piccolohealth/echo-common';
import React from 'react';
import { VisibleWithPermission } from '../../generic/VisibleWithPermission';
import { ControlTooltip } from '../controls/ControlTooltip';
import { InstanceFormatIcon } from '../controls/InstanceFormatIcon';
import { InstanceFormat, InstanceFormatTag } from '../controls/InstanceFormatTag';
import { InstanceSizeBadge } from '../controls/InstanceSizeBadge';
import { InstanceFrameSizeBadge } from '../controls/InstanceFrameSizeBadge';
import { MachineTag } from '../../../features/dicom/components/MachineTag';

export interface InstanceInfo {
  index: number;
  total: number;
  viewportIndex: number;
  instance: Instance;
}

interface Props {
  info: InstanceInfo;
  format: InstanceFormat;
}

export const InstanceOverlayInfo = (props: Props) => {
  const { info, format } = props;
  const { index, total, instance } = info;

  const clipProgress = (
    <Text fontSize="xs" fontWeight="bold" color="white">
      {index + 1}/{total}
    </Text>
  );

  const stage =
    instance.dicom.stage && instance.dicom.stage !== Stage.Unknown ? instance.dicom.stage : null;
  const view =
    instance.dicom.view && instance.dicom.view !== View.Unknown
      ? renderDicomView(instance.dicom.view)
      : null;
  const stageView =
    stage || view ? (
      <Text fontSize="xs" fontWeight="semibold" color="white">
        {stage} {view}
      </Text>
    ) : null;

  const heartRate = instance.dicom.heartRate ? (
    <Text fontSize="xs" fontWeight="semibold" color="white">
      {instance.dicom.heartRate} bpm
    </Text>
  ) : null;

  const machine =
    instance.dicom.manufacturer && instance.dicom.model
      ? { model: instance.dicom.model, manufacturer: instance.dicom.manufacturer }
      : null;

  const instanceInfo = (
    <VisibleWithPermission permission={PERMISSIONS.organizationSupport}>
      <HStack>
        <InstanceFormatTag format={format} />
        <InstanceSizeBadge size={instance.size ?? 0} />
        <InstanceFrameSizeBadge
          size={instance.size ?? 0}
          frameCount={instance.dicom.numberOfFrames ?? 1}
        />
      </HStack>
      <HStack>{machine && <MachineTag machine={machine} />}</HStack>
    </VisibleWithPermission>
  );

  const instanceFormatIcon = <InstanceFormatIcon format={format} />;

  const contentDateTime =
    instance.dicom.contentDateTime &&
    DateTime.fromISO(instance.dicom.contentDateTime as unknown as string);

  const timestamp =
    contentDateTime && contentDateTime.isValid ? (
      <Text fontSize="xs" fontWeight="semibold" color="white">
        {contentDateTime?.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
      </Text>
    ) : null;

  return (
    <ControlTooltip
      label={
        <Stack>
          <HStack divider={<Text mx={2}>-</Text>}>
            {timestamp}
            {heartRate}
          </HStack>

          {instanceInfo}
        </Stack>
      }
    >
      <HStack>
        {instanceFormatIcon}
        {clipProgress}
        {stageView}
      </HStack>
    </ControlTooltip>
  );
};
