import { Query } from '@cubejs-client/core';

export interface CubeChartQuery extends Query {
  chart: {
    x: string;
    y: string;
    color: string;
  };
}

export const queries = {
  reportsFinalizedCount: {
    measures: ['Report.finalizedCount'],
    timeDimensions: [
      {
        dimension: 'Report.createdAt',
      },
    ],
  } as Query,

  reportsSharedCount: {
    measures: ['ReportShare.count'],
    timeDimensions: [
      {
        dimension: 'ReportShare.createdAt',
      },
    ],
  } as Query,

  reportsUploadedCount: {
    measures: ['Report.count'],
    timeDimensions: [
      {
        dimension: 'Report.createdAt',
      },
    ],
  } as Query,

  reportsUploadedOverTime: {
    measures: ['Report.count'],
    dimensions: ['ReportTemplate.id', 'ReportTemplate.name', 'ReportTemplate.labelColor'],
    timeDimensions: [
      {
        dimension: 'Report.createdAt',
        granularity: 'day',
      },
    ],
    chart: {
      id: 'ReportTemplate.id',
      x: 'ReportTemplate.name',
      y: 'Report.count',
      color: 'ReportTemplate.labelColor',
    },
  } as CubeChartQuery,

  reportsByReportTemplateName: {
    measures: ['Report.finalizedCount'],
    dimensions: ['ReportTemplate.name', 'ReportTemplate.labelColor'],
    chart: {
      x: 'ReportTemplate.name',
      y: 'Report.finalizedCount',
      color: 'ReportTemplate.labelColor',
    },
  } as CubeChartQuery,

  reportsByCardiologist: {
    measures: ['Report.finalizedCount'],
    dimensions: ['ReportVariable.value'],
    segments: ['ReportVariable.cardiologist'],
    order: [['Report.finalizedCount', 'desc']],
    limit: 10,
    timeDimensions: [
      {
        dimension: 'Report.createdAt',
      },
    ],
    chart: {
      x: 'ReportVariable.value',
      y: 'Report.finalizedCount',
    },
  } as CubeChartQuery,

  reportsBySonographer: {
    measures: ['Report.finalizedCount'],
    dimensions: ['ReportVariable.value'],
    segments: ['ReportVariable.sonographer'],
    order: [['Report.finalizedCount', 'desc']],
    limit: 10,
    chart: {
      x: 'ReportVariable.value',
      y: 'Report.finalizedCount',
    },
  } as CubeChartQuery,

  reportsByReferrer: {
    measures: ['Report.finalizedCount'],
    dimensions: ['ReportVariable.value'],
    segments: ['ReportVariable.referringPhysician'],
    order: [['Report.finalizedCount', 'desc']],
    limit: 10,
    chart: {
      x: 'ReportVariable.value',
      y: 'Report.finalizedCount',
    },
  } as CubeChartQuery,

  reportsByUniquePatientName: {
    measures: ['Report.finalizedCount'],
    segments: ['ReportVariable.patientName'],
    timeDimensions: [
      {
        dimension: 'Report.createdAt',
      },
    ],
  },
};
