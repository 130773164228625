import { Wrap, WrapItem } from '@chakra-ui/react';
import { Distribution, ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { Error } from '../../../components/generic/Error';
import { showModal } from '../../../components/generic/Modal';
import { Spin } from '@piccolohealth/ui';
import { useReportDistributionsQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { DistributionAddButton } from './DistributionAddButton';
import { DistributionAddModal } from './DistributionAddModal';
import { DistributionCard } from './DistributionCard';

interface Props {
  reportId: string;
}

export const Distributions = (props: Props) => {
  const { reportId } = props;

  const { organization } = useAppContext();

  const onAddDistribution = React.useCallback((reportId: string, reportStatus: ReportStatus) => {
    showModal(DistributionAddModal, {
      reportId,
      reportStatus,
    });
  }, []);

  const { data, isLoading, error } = useReportDistributionsQuery({
    organizationId: organization.id,
    reportId,
  });

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const distributions = (data?.organization?.report?.distributions as Distribution[]) || [];
  const reportStatus = data?.organization?.report?.status as ReportStatus;

  return (
    <Wrap spacing={8} p={8}>
      {distributions.map((distribution) => (
        <WrapItem key={distribution.id} minH="340px">
          <DistributionCard distribution={distribution} reportStatus={reportStatus} />
        </WrapItem>
      ))}
      <WrapItem minH="340px">
        <DistributionAddButton onClick={() => onAddDistribution(reportId, reportStatus)} />
      </WrapItem>
    </Wrap>
  );
};
