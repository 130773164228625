import { Query, TimeDimensionGranularity } from '@cubejs-client/core';
import { DateTime } from '@piccolohealth/util';
import _ from 'lodash';
import React from 'react';
import { useAppContext } from './useAppContext';

export enum TimeRange {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

const getCubeInfo = (query: Query, timeRange: TimeRange) => {
  const timeDimensionObj = (query.timeDimensions || [])[0] || {};
  const cube = query.measures?.[0]?.split('.')?.[0] ?? 'Reports';
  const timeDimension = timeDimensionObj.dimension || `${cube}.createdAt`;
  const granularity = timeDimensionObj.granularity
    ? (timeRangeMappings[timeRange] as TimeDimensionGranularity)
    : undefined;

  return {
    timeDimension,
    granularity,
  };
};

const timeRangeMappings: { [id: string]: string } = {
  [TimeRange.DAY]: 'hour',
  [TimeRange.WEEK]: 'day',
  [TimeRange.MONTH]: 'day',
  [TimeRange.YEAR]: 'month',
};

const timeRangeOptions = [
  {
    value: TimeRange.DAY,
    label: 'Day',
  },
  {
    value: TimeRange.WEEK,
    label: 'Week',
  },
  {
    value: TimeRange.MONTH,
    label: 'Month',
  },
  {
    value: TimeRange.YEAR,
    label: 'Year',
  },
];

export const useTimeRangeCubeQuery = () => {
  const { organization } = useAppContext();
  const [timeRange, setTimeRange] = React.useState<TimeRange>(TimeRange.MONTH);

  const withTimeRange = <A extends Query>(query: A): A => {
    const { timeDimension, granularity } = getCubeInfo(query, timeRange);

    const duration = _.pickBy(
      {
        days: timeRange === 'day' ? 1 : null,
        weeks: timeRange === 'week' ? 1 : null,
        months: timeRange === 'month' ? 1 : null,
        years: timeRange === 'year' ? 1 : null,
      },
      _.identity,
    );

    const end = DateTime.now();
    const begin = end.minus(duration);

    return {
      ...query,
      timezone: organization.timezone,
      timeDimensions: [
        {
          dimension: timeDimension,
          dateRange: [begin.toString(), end.toString()],
          granularity,
        },
      ],
    };
  };

  const withHistoricalTimeRange = <A extends Query>(query: A): A => {
    const { timeDimension } = getCubeInfo(query, timeRange);

    const duration = _.pickBy(
      {
        days: timeRange === TimeRange.DAY ? 1 : null,
        weeks: timeRange === TimeRange.WEEK ? 1 : null,
        months: timeRange === TimeRange.MONTH ? 1 : null,
        years: timeRange === TimeRange.YEAR ? 1 : null,
      },
      _.identity,
    );

    const end = DateTime.now();
    const begin = end.minus(duration);
    const historicalEnd = begin;
    const historicalBegin = historicalEnd.minus(duration);

    return {
      ...query,
      timezone: organization.timezone,
      timeDimensions: [
        {
          dimension: timeDimension,
          compareDateRange: [
            [begin.toString(), end.toString()],
            [historicalBegin.toString(), historicalEnd.toString()],
          ],
        },
      ],
    };
  };

  return {
    timeRangeOptions,
    timeRange,
    setTimeRange,
    withTimeRange,
    withHistoricalTimeRange,
  };
};
