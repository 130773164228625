import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  MenuItemOption,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FinalizeBehavior,
  PERMISSIONS,
  Report,
  ReportStatus,
  User,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { DistributionOnFinalizeSummary } from '../../../features/distributions/components/DistributionOnFinalizeSummary';
import { useUpdateReportStatusMutation } from '../../../graphql/hooks/useUpdateReportStatusMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { Divider } from '../../generic/Divider';
import { createModal, showModal } from '../../generic/Modal';
import { FinalizeBehaviorSummary } from './FinalizeBehaviorSummary';
import { ReportStatusWarnings } from './ReportStatusWarnings';

export const finalizeWarningsSchema = (user: User) =>
  Yup.object().shape({
    sex: Yup.object().shape({ value: Yup.string().nullable().required('No sex selected') }),
    patientName: Yup.object().shape({
      value: Yup.string().nullable().required('No Patient name entered'),
    }),
    patientId: Yup.object().shape({
      value: Yup.string().nullable().required('No Patient ID entered'),
    }),
    dob: Yup.object().shape({
      value: new Yup.DateSchema().nullable().required('No date of birth entered'),
    }),
    cardiologist: Yup.object()
      .shape({
        value: Yup.array().nullable().min(1, 'No reporting cardiologist selected'),
      })
      .test('cardiologistPresent', ({ value }, context) => {
        const values = value ?? [];

        if (_.isEmpty(values) || _.includes(values, user.name)) {
          return true;
        }

        return context.createError({
          message: `You (${user.name}) are not in the list of assigned cardiologists (${values.join(
            ',',
          )})`,
          path: 'cardiologist',
        });
      })
      .nullable(),
  });

interface FormValues {
  finalizeBehavior: FinalizeBehavior;
  nextReportId?: string | null;
}

interface FinalizeModalProps {
  report: Report;
}

const FinalizeModal = createModal<FinalizeModalProps>((props) => {
  const { modal, report } = props;

  const { user, organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateReportStatusMutation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    finalizeBehavior: Yup.string().required(),
    nextReportId: Yup.string().nullable(),
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      finalizeBehavior: user.settings.finalizeBehavior,
      nextReportId: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onFinalizeBehavior = React.useCallback(
    (behavior: FinalizeBehavior, nextReportId?: string | null) => {
      switch (behavior) {
        case FinalizeBehavior.NextReport:
          return navigate(`/organizations/${organization.id}/reports/${nextReportId}`);
        case FinalizeBehavior.Reports:
          return navigate(`/organizations/${organization.id}/reports`);
        case FinalizeBehavior.Noop:
          return;
      }
    },
    [navigate, organization.id],
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          reportId: report.id,
          updateReportStatusRequest: {
            status: ReportStatus.Finalized,
          },
        })
        .then(() => {
          successToast('Report finalized');
          onFinalizeBehavior(values.finalizeBehavior, values.nextReportId);
        })
        .catch(() => {
          errorToast('Error updating report status');
        });

      modal.hide();
    },
    [mutation, organization.id, report.id, modal, successToast, onFinalizeBehavior, errorToast],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Finalize report</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Finalize and distribute this report, generating PDFs
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Stack spacing={6}>
                <ReportStatusWarnings schema={finalizeWarningsSchema(user)} report={report} />
                <Divider />
                <DistributionOnFinalizeSummary reportId={report.id} />
                <FinalizeBehaviorSummary report={report} />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button onClick={modal.hide}>Close</Button>
                <HookedSubmitButton
                  isDisabled={false}
                  colorScheme="purple"
                  data-pw="reportStatusUpdateModalSubmitButton"
                >
                  Finalize
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});

export const FinalizeMenuItemOption = (props: { report: Report }) => {
  const { report } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsFinalize).value;
  const isChecked = report.status === ReportStatus.Finalized;
  const isDisabled = !hasPermission || isChecked;

  return (
    <>
      <MenuItemOption
        onClick={() => showModal(FinalizeModal, { report })}
        isDisabled={isDisabled}
        isChecked={isChecked}
        data-pw={`reportStatusUpdateMenuItemOption-${ReportStatus.Finalized}`}
      >
        Finalized
      </MenuItemOption>
    </>
  );
};
