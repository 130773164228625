import * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { ShareReportImport } from '../mutations/shareReportImport.mutation';

export const useShareReportImportMutation = (
  options?: MutationOptions<
    Types.ShareReportImportMutation,
    Types.ShareReportImportMutationVariables
  >,
) => {
  return useMutation(gqlFetcher(ShareReportImport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
    },
  });
};
