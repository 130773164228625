import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { Card, CardHeader } from '../../generic/Card';

interface Props extends FlexProps {
  title: string;
}

export const CubeCard = (props: React.PropsWithChildren<Props>) => {
  const { title, children, ...rest } = props;

  return (
    <Card h="100%">
      <CardHeader title={title} />
      <Flex h="100%" {...rest}>
        {children}
      </Flex>
    </Card>
  );
};
