import { Box, Wrap, WrapItem } from '@chakra-ui/react';
import { Empty } from '@piccolohealth/ui';
import { PERMISSIONS, ReportAttachment } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { showModal } from '../../../components/generic/Modal';
import { Spin } from '@piccolohealth/ui';
import { ReportAttachmentsUploadModal } from '../../../components/modals/ReportAttachmentsUploadModal';
import { useReportAttachmentsQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { AddAttachmentButton } from './AddAttachmentButton';
import { AttachmentCard } from './AttachmentCard';

interface Props {
  reportId: string;
}

export const Attachments = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();
  const hasAddAttachmentPermission = usePermission(PERMISSIONS.reportAttachmentCreate).value;
  const hasReadAttachmentPermission = usePermission(PERMISSIONS.reportAttachmentRead).value;

  const { data, isLoading } = useReportAttachmentsQuery({
    organizationId: organization.id,
    reportId,
  });

  const attachments = (data?.organization?.report?.attachments as ReportAttachment[]) ?? [];

  const onAddAttachment = React.useCallback(() => {
    showModal(ReportAttachmentsUploadModal, { reportId });
  }, [reportId]);

  if (isLoading) {
    return <Spin />;
  }

  if (_.isEmpty(attachments) && !hasReadAttachmentPermission) {
    return (
      <Empty
        title="No attachments"
        description="There were no attachments uploaded for this report"
      />
    );
  }

  return (
    <Box h="full" p={8}>
      <Wrap spacing={8} h="full">
        {attachments.map((attachment) => (
          <WrapItem key={attachment.id} minH="340px">
            <AttachmentCard reportId={reportId} attachment={attachment} />
          </WrapItem>
        ))}
        {hasAddAttachmentPermission && (
          <WrapItem minH="340px">
            <AddAttachmentButton onClick={onAddAttachment} />
          </WrapItem>
        )}
      </Wrap>
    </Box>
  );
};
