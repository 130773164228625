import { Box, ButtonGroup, Flex, HStack, Spacer, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportTemplateVariableChoice } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HookedFormItem } from '../../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { HookedResetButton } from '../../../forms/hookform/HookedResetButton';
import { HookedSubmitButton } from '../../../forms/hookform/HookedSubmitButton';
import { Divider } from '../../../generic/Divider';
import { HookedSortableChoicesControl } from './HookedSortableChoicesControl';

export const validationSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  site: Yup.string().required('Required'),
  choices: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        value: Yup.string().required(),
        default: Yup.boolean().required(),
      }),
    )
    .min(1, 'At least one choice is required'),
});

export interface FormValues {
  id: string;
  name: string;
  category: string;
  site: string;
  choices: ReportTemplateVariableChoice[];
}

interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
}

export const VariableForm = (props: Props) => {
  const { initialValues, onSubmit } = props;

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = React.useCallback(
    (onSubmit: SubmitHandler<FormValues>) =>
      (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
        e?.preventDefault();
        methods.handleSubmit(onSubmit)(e);
        e?.stopPropagation();
      },
    [methods],
  );

  return (
    <Box p={4}>
      <FormProvider {...methods}>
        <Stack as="form" onSubmit={handleSubmit(onSubmit)} h="full">
          <Stack align="left" h="full" spacing={4}>
            <HookedFormItem name="name" label="Name" helperText="The name of the dropdown">
              <HookedInput name="name" size="sm" placeholder="Eg: Systolic function" />
            </HookedFormItem>
            <HStack w="full" h="full">
              <HookedFormItem
                name="site"
                label="Finding Site"
                helperText="Which finding site is this dropdown related to?"
              >
                <HookedInput name="site" size="sm" placeholder="Eg: Left ventricle" />
              </HookedFormItem>
              <HookedFormItem
                name="category"
                label="Category"
                helperText="What category would you like the choice to be grouped under?"
              >
                <HookedInput name="category" size="sm" placeholder="Eg: Structure" />
              </HookedFormItem>
            </HStack>

            <Divider />

            <HookedFormItem
              name="choices"
              label="Choices"
              helperText="Choice values should be written as you want them to appear in the middle of a sentence. Eg: 'normal' rather than 'Normal'."
            >
              <HookedSortableChoicesControl name="choices" />
            </HookedFormItem>
          </Stack>
          <Spacer />
          <Flex justify="end" mt={4}>
            <ButtonGroup>
              <HookedResetButton>Reset</HookedResetButton>
              <HookedSubmitButton>Save</HookedSubmitButton>
            </ButtonGroup>
          </Flex>
        </Stack>
      </FormProvider>
    </Box>
  );
};
